import { filtersAddon, exportsAddon } from "@cp/store/addons";

import { nonEmpty } from "@cp/utils/itertees";
import { get, isObject } from "@cp/utils/objectUtils";
import { findByObj } from "@cp/utils/arrayUtils";
import { deepKeyTransform } from "@cp/utils/collectionUtils";

// TRUSTING MICHAEL A LOT.... [grimmace]
export default function addFromMeta({ filterOptions, initialMeta: meta }) {
  // parse filters from meta
  const metaFilters = get(meta, "filters", []);
  if (nonEmpty(metaFilters) && metaFilters.length) {
    const metaFiltersOptions = get(meta, "filters_options", []);

    const filters = metaFilters.map(
      ({
        label,
        key,
        search,
        sub_query,
        type = "select",
        values = [],
        multiple = false,
      }) => {
        const initialValue =
          get(filterOptions, ["defaults", key]) ||
          get(meta, ["additional_meta", "defaults", "filters", key]);
        const initialSelected = get(meta, [
          "additional_meta",
          "selected_filters",
          key,
        ]);

        if (sub_query)
          return {
            type: "sub_query",
            key,
            urlKey: sub_query,
            label,
            multiple,
            initialValue,
            initialSelected,
          };

        if (search)
          return {
            type: "search",
            key,
            urlKey: search,
            label,
            multiple,
            initialValue,
            initialSelected,
          };

        if (key === "q_text") return "q_text";

        return {
          type,
          key,
          items: values,
          label,
          multiple,
          initialValue,
          initialSelected,
        };
      }
    );
    this.add(filtersAddon(filters, metaFiltersOptions));
  }

  // parse scopes and subScopes from meta
  const structuresMeta = get(meta, "structures", []);
  const structures = isObject(structuresMeta) ? [] : structuresMeta;
  const scopeMeta = findByObj(structures, { key: "scope" });

  if (nonEmpty(scopeMeta) && scopeMeta.values && scopeMeta.values.length) {
    const scopes = scopeMeta.values;
    const subScopes = scopes.reduce((r, scope) => {
      r[scope.value] = scope.sub_scopes;
      return r;
    }, {});
    const defaultScope =
      get(this.params, "structures.scope") || // from url
      get(meta, "additional_meta.defaults.scope") || // from meta
      scopes[0].value; // pick the first scope as default
    this.add({
      state: {
        scopes,
        subScopes,
        [this.keys.params]: { structures: { scope: defaultScope } },
      },
    });
  }

  // parse exports from meta
  const metaExport = get(meta, "additional_meta.export_options", {});
  if (nonEmpty(metaExport)) {
    this.add(exportsAddon(metaExport));
  }

  const reportTypeMeta = findByObj(structures, { key: "report_type" });
  if (reportTypeMeta && reportTypeMeta.values.length) {
    const reportType =
      this.findConfigValue("state.reportType") || // from url
      get(
        meta,
        "additional_meta.defaults.report_type",
        reportTypeMeta.values[0].value
      );
    const metric =
      this.findConfigValue("state.metric") || // from url
      get(
        reportType,
        ["options", "metric", 0, "value"],
        "agreement_importance"
      ); // from meta
    this.add({
      state: {
        metric,
        [this.keys.params]: {
          structures: {
            report_type: reportType,
          },
        },
        reportTypes: reportTypeMeta.values,
      },
    });
  }

  const dateIntervalMeta = findByObj(structures, { key: "date_interval" });
  if (dateIntervalMeta && dateIntervalMeta.values.length) {
    const dateInterval =
      this.findConfigValue([
        "state",
        this.keys.params,
        "structures",
        "date_interval",
      ]) || // from url
      get(meta, "additional_meta.defaults.interval") || // from meta
      get(dateIntervalMeta, ["values", 0, "value"], ""); // from first value
    this.add({
      state: {
        [this.keys.params]: { structures: { date_interval: dateInterval } },
        dateIntervals: dateIntervalMeta,
      },
    });
  }

  if (typeof this.addMetaMap === "function") {
    this.addMetaMap(
      "statCards",
      "summary_object.cards",
      get(meta, "summary_object.cards", []),
      deepKeyTransform
    );
  }
}
