<template>
  <div>
    <PortfolioBreadcrumbs />
    <cp-h1>
      {{ selectedScope.label }}

      <template #right>
        <cp-filters-menu :module="table" />
      </template>
    </cp-h1>

    <list-stats-cards v-bind="{ statCards, table }" />

    <v-card>
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        v-model="tableSelected"
        class="clickable-rows"
        @click:row="rowClick"
        :headers="headers"
        hide-default-footer
      >
        <template #item.ingage_stats.current_score="{value, item}">
          <span
            v-if="value"
            :set="(diff = parseDiff(value, item.ingage_stats.previous_score))"
          >
            <a :id="`open-engagement-modal-${item.m_id}`" @click.stop.prevent>
              {{ diff.score }}
              <span v-if="diff.delta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/employee_engagement_trends`"
              :activator="`#open-engagement-modal-${item.m_id}`"
              width="800"
            />
          </span>
        </template>

        <template #item.intouch_stats.agreement_importance="{value, item}">
          <span
            v-if="value"
            :set="
              (diff = parseDiff(
                value,
                get(
                  item,
                  'intouch_stats.previous_year_results.agreement_importance'
                )
              ))
            "
          >
            <a :id="`open-satisfaction-modal-${item.m_id}`" @click.stop.prevent>
              {{ diff.score }}
              <span v-if="diff.delta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/resident_satisfaction_trends`"
              :activator="`#open-satisfaction-modal-${item.m_id}`"
              width="800"
            />
          </span>
        </template>

        <template
          #item.introduce_stats.progress.complete_percentage="{value, item}"
        >
          <span v-if="value">
            {{ roundPrct(value) }}
          </span>
          <!-- <span v-if="value">
            <a href="#" :id="`open-progress-modal-${item.m_id}`" @click.stop>
              {{ roundPrct(value) }}
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/plan-progress`"
              :activator="`#open-progress-modal-${item.m_id}`"
              width="800"
            />
          </span> -->
        </template>

        <template
          #item.introduce_stats.compliance.compliant_percentage="{value, item}"
        >
          <span v-if="value">
            {{ roundPrct(value) }}
          </span>
          <!-- <span
            v-if="value"
            :set="
              (diff = parseDiff(
                value,
                item.introduce_stats.compliance.previous_score
              ))
            "
          >
            <a href="#" :id="`open-compliance-modal-${item.m_id}`" @click.stop>
              {{ diff.score }}%
              <span v-if="diff.showDelta">
                <v-icon v-if="diff.delta > 0" color="success"
                  >mdi-trending-up</v-icon
                >
                <v-icon v-if="diff.delta < 0" color="error"
                  >mdi-trending-down</v-icon
                >
                <span
                  :class="{
                    'success--text': diff.delta > 0,
                    'error--text': diff.delta < 0,
                  }"
                >
                  {{ Math.abs(diff.delta) }}
                </span>
              </span>
            </a>
            <widget-modal
              v-if="value"
              :url="`properties/${item.m_id}/plan-compliance`"
              :activator="`#open-compliance-modal-${item.m_id}`"
              width="800"
            />
          </span> -->
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { get } from "@cp/utils/objectUtils";
import { properize } from "@cp/utils/stringUtils";

import { portfolioTable } from "@/store/modules/portfolio";
import { multiScopeMixin } from "@/widgets/mixins";
import { roundPrct } from "@/lib/formats";
import colorsAndIcons from "@/lib/colorsAndIcons";
import PortfolioBreadcrumbs from "@/components/portfolio/PortfolioBreadcrumbs.vue";

const scopeToShowRoute = {
  regions: "region",
  locations: "property",
};

export default {
  components: { PortfolioBreadcrumbs },
  name: "Portfolio",
  mixins: [portfolioTable.mixin, portfolioTable.filtersMixin, multiScopeMixin],
  data() {
    return {
      instance: portfolioTable,
    };
  },
  computed: {
    ...mapGetters("auth", { has: "clientHasService" }),
    headers() {
      const headers = [
        {
          text: this.selectedScope.label,
          value: "name",
          sortable: true,
        },
      ];

      if (this.has("ingage"))
        headers.push({
          text: "Engagement",
          value: "ingage_stats.current_score",
          align: "center",
        });

      if (this.has("intouch"))
        headers.push({
          text: "Satisfaction",
          value: "intouch_stats.agreement_importance",
          align: "center",
          sortable: true,
        });

      if (this.has("introduce"))
        headers.push(
          {
            text: "Plans Progress",
            value: "introduce_stats.progress.complete_percentage",
            align: "center",
            sortable: true,
          },
          {
            text: "Plans Compliance",
            value: "introduce_stats.compliance.compliant_percentage",
            align: "center",
            sortable: true,
          }
        );

      return headers;
    },
    statCards() {
      const summaryCard = {
        title: "Summary",
        icon: "home-city",
        basePath: ["summary_object", "general"],
        stats: [
          ,
          // ["Areas", "areas"],
          [
            "Locations",
            "locations",
            {
              icon: colorsAndIcons.property.icon,
              iconProps: { color: colorsAndIcons.property.color },
            },
          ],
          // ["Properties", "properties"],
        ],
      };

      if (get(this.meta, "summary_object.general.regions", 0) > 1)
        summaryCard.stats.splice(0, 0, [
          "Regions",
          "regions",
          {
            icon: colorsAndIcons.region.icon,
            iconProps: { color: colorsAndIcons.region.color },
          },
        ]);

      if (this.has(["ingage", "introduce"])) {
        summaryCard.stats.push(["Employees", "active_employees"]);
      }

      if (this.has("intouch")) {
        summaryCard.stats.push(
          ["Residents", "active_primary_resident_count"],
          ["Units", "units"]
        );
      }

      const statCards = [summaryCard];

      if (this.has("ingage"))
        statCards.push({
          title: "Engagement",
          cpIcon: "ingage",
          basePath: ["summary_object", "engagement"],
          stats: [
            [
              "Engagement",
              "score",
              {
                format: Math.round,
                icon: "thumb-up",
                iconProps: { color: "success" },
              },
            ],
            ["Response Rate", "response_rate", { format: roundPrct }],
            ["Likely to Remain", "likelihood_to_remain", { format: roundPrct }],
            ["Tenure", "tenure", { append: "Y" }],
            // ["Turnover", "turnover", { format: x => `${Math.round(x)} days` }],
          ],
        });
      if (this.has("intouch"))
        statCards.push({
          title: "Satisfaction",
          cpIcon: "redstone",
          basePath: ["summary_object", "satisfaction"],
          stats: [
            [
              "Satisfaction",
              "score",
              {
                format: Math.round,
                icon: "thumb-up",
                iconProps: { color: "success" },
              },
            ],
            ["Response Rate", "response_rate", { format: roundPrct }],
            ["Likely to Renew", "likelihood_to_renew", { format: roundPrct }],
          ],
        });

      if (this.has("introduce"))
        statCards.push({
          title: "Plans",
          cpIcon: "fiver",
          basePath: ["summary_object", "development"],
          stats: [
            [
              "Compliance",
              "compliance_score",
              {
                format: roundPrct,
                icon: "bullseye-arrow",
                iconProps: { color: "orange" },
              },
            ],
            ["Employees", "employees", { showIfEmpty: "0" }],
            ["Pending", "pending"],
            ["Past Due", "past_due"],
            ["Can't Complete", "cant_complete"],
          ],
        });

      return statCards;
    },
    queryScope() {
      return this.$route.query.scope;
    },
  },
  methods: {
    get,
    roundPrct,
    properize,
    parseDiff(currentValue, previousValue) {
      const score = Math.round(parseFloat(currentValue));
      const delta = previousValue
        ? score - Math.round(parseFloat(previousValue))
        : false;
      return { score, delta, showScore: !!score, showDelta: !!delta };
    },
    rowClick(item) {
      const name = scopeToShowRoute[this.scope];
      this.$router.push({ name, params: { id: item.m_id } });
    },
  },
  mounted() {
    if (this.scope !== this.$route.query.scope) {
      this.updateScopeFromRouteQuery();
    }
  },
};
</script>

<style scoped>
::v-deep th.text-center span:before {
  content: " ";
  display: inline-block;
  width: 16px;
}
</style>
