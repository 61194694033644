<template>
  <div>
    <PortfolioBreadcrumbs :parent="parent" :loading="loading" />
    <widget-v2
      v-if="showWidget"
      :url="baseUrl + `/${$route.params.id}/${widget}`"
      @initialized="setParent"
    />
    <component v-else :is="widget" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { mapItem } from "@cp/utils/jsonapiUtils";
import { get } from "@cp/utils/objectUtils";

const baseUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/regions`;

export default {
  data() {
    return {
      loading: true,
      parent: { region: {}, name: "", id: "" },
      baseUrl,
    };
  },
  computed: {
    ...mapGetters("haystackUser", ["thisAppAccessLevel", "clientServices"]),
    ...mapGetters("portfolio", ["regionWidgets"]),
    backButtonRoute() {
      if (["regional_manager"].includes(this.thisAppAccessLevel))
        return { name: "portfolio" };
      if (["client-admin"].includes(this.thisAppAccessLevel))
        return { name: "portfolio" };
      return {};
    },
    widget() {
      return this.$route.params.widget;
    },
    showWidget() {
      return this.regionWidgets.includes(this.widget);
    },
  },
  methods: {
    setParent(instance) {
      this.loading = false;
      this.parent = mapItem(
        get(this.$store.state, instance.p.s.meta + ".parent")
      );
    },
  },
  created() {
    if (!this.$route.params.widget) {
      const { name, params } = this.$route;
      this.$router.replace({
        name,
        params: { ...params, widget: this.regionWidgets[0] },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.CpH1::v-deep h1 {
  line-height: 0.95em;
}

.ShowNav {
  .v-list-item {
    padding-left: 0;
    padding-right: 0;
    &:hover {
      &,
      & * {
        text-decoration: none;
      }
    }
    &.v-list-item--active:before {
      background: $primary;
    }

    &.v-list-item--active .v-icon {
      color: $primary;
    }
  }

  .v-list-item__content {
    font-weight: 500;
    font-size: 0.85rem;
  }

  .v-list-item__icon {
    align-self: center;
    margin: 0 5px;
  }

  .v-list-item__action {
    align-self: stretch;
    margin: 0 5px;
  }
}
</style>
