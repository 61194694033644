import store from "@cp/store/appStore";
import { Client } from "@cp/lib";
import { deepMerge, get, getFirst, isEmpty } from "@cp/utils/objectUtils";
import { mapResponse } from "@cp/utils/jsonapiUtils";
import {
  mergeMixins,
  ApiDataMixin,
  ApiDataWithMeta,
  HaystackSearch,
  Item,
} from "@cp/store/mixins";

import { Calculator } from "@/store/mixins/Calculator";
import {
  Widget,
  BasicWidget,
  CalculatorWidget,
  TableWidget,
} from "@/store/mixins/Widget";
// import widgetArgs from "@/widgets/args";

const haystackUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`;

const client = new Client({ baseUrl: haystackUrl });

const dataTypeMapDataMap = {
  calculator: data => data.results,
  load_more_calculator: data => data.results,
  jsonapi_array: data => mapResponse({ data }),
  jsonapi_hash: data => mapResponse({ data }),
};

const dataTypeClassMap = {
  calculator: CalculatorWidget,
  jsonapi_array: TableWidget,
  jsonapi_hash: Widget,
  basic_json_hash: BasicWidget,
  basic_json_array: BasicWidget,
};

export function registerWidget({ data, meta, config, widgetId }) {
  const id = widgetId || get(meta, "widget.id");
  const dataType = config.dataType || get(meta, "widget.data_type");

  const initialValue = dataTypeMapDataMap.hasOwnProperty(dataType)
    ? dataTypeMapDataMap[dataType](data)
    : data;

  const args = {
    parent: widgetPage,
    module: id,
    name: "widget",
    url: meta.endpoint,
    initialValue,
    initialMeta: meta,
    ...config,
    // ...widgetArgs(id),
  };

  const cls = dataTypeClassMap[dataType] || Widget;

  let instance = new cls(args);

  if (store.hasModule(instance.modulePath)) {
    // remove the old module
    store.unregisterModule(instance.modulePath);
  }
  store.registerModule(instance.modulePath, instance.toVuex);
  return instance;
}

export async function loadWidget({
  url,
  query,
  parent,
  moduleId,
  widgetId: widgetIdArg,
}) {
  const initialParams = {};
  const { scope, metric, reportType, dateInterval, ...filters } = query;
  if (scope) deepMerge(initialParams, { structures: { scope } });
  if (reportType)
    deepMerge(initialParams, { structures: { report_type: reportType } });
  if (dateInterval)
    deepMerge(initialParams, { structures: { date_interval: dateInterval } });
  if (!isEmpty(filters)) deepMerge(initialParams, { filters });
  const response = await client.get(url, initialParams);
  const responseData = get(response, "data.data");
  const dataType = get(response, "data.meta.widget.data_type");
  const widgetId = widgetIdArg || get(response, "data.meta.widget.id");

  if (!widgetId) throw `API Error: ${url} did not send "meta.widget.id"`;

  const initialValue = dataTypeMapDataMap.hasOwnProperty(dataType)
    ? dataTypeMapDataMap[dataType](responseData)
    : responseData;

  const state = {};
  if (metric) state.metric = metric;
  if (reportType) state.reportType = reportType;

  const args = {
    // in browser, $widgets.subModuleRefs will have widget classes
    parent: parent || widgetPage,
    module: parent ? moduleId : widgetId,
    name: "widget",
    url: get(response, "data.meta.endpoint"),
    params: initialParams,
    initialValue,
    initialMeta: get(response, "data.meta"),
    filterOptions: { defaults: filters },
    state,
  };

  const cls = dataTypeClassMap[dataType] || Widget;
  const instance = new cls(args);
  if (store.hasModule(instance.modulePath)) {
    // remove the old module
    store.unregisterModule(instance.modulePath);
  }
  store.registerModule(instance.modulePath, instance.toVuex);
  return instance;
}

export function unregisterWidget(arg) {
  if (!arg) return;
  if (Array.isArray(arg)) store.unregisterModule(arg);
  else if (arg.hasOwnProperty("modulePath"))
    store.unregisterModule(arg.modulePath);
  else {
    const id = getFirst(arg, ["widget.id", "id"]);
    if (!id) return;
    store.unregisterModule(["widgets", id]);
  }
}

class WidgetPage extends ApiDataMixin {
  constructor(config) {
    super(config);

    const search = new HaystackSearch({
      parent: this,
      module: "search",
      urlKey: "undefined",
      name: "items",
      params: { filters: { only_mine: true } },
    });
    this.search = search;

    const item = new Item({
      baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`,
      url: "/undefined/:id",
      parent: this,
      module: "item",
    });
    this.item = item;

    const widget = new ApiDataWithMeta({
      baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1`,
      url: "/undefined/:id/:widget",
      urlTemplate: true,
      name: "widget",
      jsonApiResponse: false,
      initialValue: [],
    });
    this.widget = widget;

    this.add({
      modules: {
        search: search.toVuex,
        item: item.toVuex,
        widget: widget.toVuex,
      },
    });
  }

  setModel({ search, endpoint = "/undefined/:id/:widget" } = {}) {
    this.search.client.axios.defaults.baseURL = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/search/${search}`;
    this.item.url = endpoint.replace("/:widget", "");
    this.widget.url = endpoint;
  }
}

// fetches the list of widgets available to the current user
export const widgetPage = new WidgetPage({
  module: "widgets",
  baseUrl: `${haystackUrl}/widgets`,
  name: "categories",
  jsonApiResponse: false,
  initialValue: {
    residents: {
      label: "Resident Widgets",
      search: "residents",
      endpoint: "/en/v1/residents/:id/:widget",
      type: "single",
      widgets: [],
    },
    properties: {
      label: "Property Widgets",
      search: "locations",
      endpoint: "/properties/:id/:widget",
      type: "single",
      widgets: [],
    },
    regions: {
      label: "Region Widgets",
      search: "regions",
      endpoint: "/regions/:id/:widget",
      type: "single",
      widgets: [],
    },
    team: {
      label: "Team Widgets",
      search: "employees",
      endpoint: "/en/v1/team/:id/:widget",
      type: "single",
      widgets: [],
    },
    reports: {
      label: "Report Widgets",
      search: "reports",
      endpoint: "/en/v1/reports/:id/:widget",
      type: "single",
      widgets: [],
    },
  },
  getters: {
    widgetCategories({ categories }) {
      const { reports, ...widgets } = categories;
      return widgets;
    },
    reports({ categories }) {
      const { reports } = categories;
      return reports;
    },
  },
});

window.$widgets = widgetPage;

export const widgetCalculator = new Calculator({
  module: "widgets",
  name: "widgetCalculator",
  baseUrl: haystackUrl,
  url: "/:category/:id/:widget",
  urlTemplate: true,
  jsonApiResponse: true,
  initialMeta: {
    pagination: {},
    endpoint: "",
    filters: [],
    parent: {},
    additional_meta: {},
    structures: {
      scope: {},
      addon_options: {},
    },
    action_details: {},
    widget: {
      id: "",
      data_type: "",
      url_key: "",
    },
  },
});

export const reportCalculator = new Calculator({
  module: "widgets",
  name: "reportCalculator",
  baseUrl: haystackUrl,
  url: "/reports/:widget",
  urlTemplate: true,
  jsonApiResponse: true,
  initialMeta: {
    pagination: {},
    endpoint: "",
    filters: [],
    parent: {},
    additional_meta: {},
    structures: {
      scope: {},
      addon_options: {},
    },
    action_details: {},
    widget: {
      id: "",
      data_type: "",
      url_key: "",
    },
  },
});

window.$reportCalculator = reportCalculator;

export default mergeMixins(widgetPage, widgetCalculator, reportCalculator);
