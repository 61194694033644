<template>
  <div>
    <PortfolioBreadcrumbs :parent="parent" :loading="loading" />
    <widget-v2
      v-if="widget"
      :url="baseUrl + `/${$route.params.id}/${widget}`"
      @initialized="setParent"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { mapItem } from "@cp/utils/jsonapiUtils";
import { get } from "@cp/utils/objectUtils";

const baseUrl = `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/properties`;

export default {
  data() {
    return {
      loading: true,
      parent: { region: {}, name: "", id: "" },
      baseUrl,
    };
  },
  computed: {
    ...mapGetters("portfolio", ["locationWidgets"]),
    widget() {
      return this.$route.params.widget;
    },
  },
  methods: {
    setParent(instance) {
      this.loading = false;
      this.parent = mapItem(
        get(this.$store.state, instance.p.s.meta + ".parent")
      );
    },
  },
  created() {
    if (!this.$route.params.widget) {
      const { name, params } = this.$route;

      this.$router.replace({
        name,
        params: { ...params, widget: this.locationWidgets[0] },
      });
    }
  },
};
</script>
